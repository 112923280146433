import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import React from 'react';

import { ImInfo } from 'react-icons/im';
import styled from 'styled-components';

const StyledButton = styled.button`
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  text-transform: uppercase;
  width: auto;
  max-width: 300px;
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  gap: 10px;
  align-items: center;

  color: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--color-primary)';
      case 'yellow':
        return 'var(--color-warning)';
      case 'red':
        return 'var(--color-danger)';
      default:
        return 'var(--color-primary)';
    }
  }};

  background: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--bg-light-mint)';
      case 'yellow':
        return 'var(--bg-warning-primary)';
      case 'red':
        return 'var(--bg-danger-light)';
      default:
        return 'var(--bg-light-mint)';
    }
  }};

  border-color: ${({ severity }) => {
    switch (severity) {
      case 'green':
        return 'var(--border-teal-dark)';
      case 'yellow':
        return 'var(--border-warning)';
      case 'red':
        return 'var(--border-danger-light)';
      default:
        return 'var(--border-teal-dark)';
    }
  }};
`;

function Warnings({ button }) {
  if (button && button?.label)
    return (
      <StyledButton className="txt size-12 w-700 lh-24" severity={button?.severity}>
        {button.label}
        {button?.tooltip_content && (
          <CustomTooltip
            position="bottom"
            content={<ImInfo size={15} />}
            info={button?.tooltip_content}
          />
        )}
      </StyledButton>
    );

  return null;
}

export default Warnings;
