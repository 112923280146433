import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import Switch from 'react-switch';
import { ButtonCustom } from 'components/form-input/Button';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  setOnboardingData,
  updateProfileInfo,
  requestGetUserProfile,
  completeProfile,
  setVideoModalDisplay,
} from 'redux/modules/userProfile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from '../utils/validations';
import { useHistory } from 'react-router-dom';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import AuthLoader from 'components/AuthLoader';
import {
  subscriptionObjEnum,
  subscriptionEnum,
  subscriptionFrequencyEnum,
} from '../constants/enum/subscriptionEnum';

const TermsOfServices = ({ onClickOfTosBackBtn, onClickOfTosBtn }) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  let history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const {
    onboardingData,
    userData,
    isLoading,
    updateProfileError,
    instituteData,
    isProfileUpdateStarted,
    isUpdatedProfileCompleted,
  } = useSelector(({ userProfile }) => userProfile);

  const { institute = {} } = userData || {};

  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const [tosSwitch, setTosSwitch] = useState(
    onboardingData?.agreed_terms_of_service
      ? onboardingData?.agreed_terms_of_service
      : userData?.agreed_terms_of_service
      ? userData?.agreed_terms_of_service
      : false,
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCallProfileCompleted, setCallProfileCompleted] = useState(false);
  const [isHealthSystemOrgnisation, setHealthSystemOrgnisation] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);

  useEffect(() => {
    if (institute) {
      setHealthSystemOrgnisation(institute?.is_health_system_or_provider);
    }
  }, [institute]);

  const handleSwitchChange = (checked) => {
    checked ? setShowErrorMessage(false) : setShowErrorMessage(true);
    mixpanel.track(`User ${checked ? 'agree' : 'disagree'} terms of services`);
    setTosSwitch(checked);
  };
  const onClickOfTosConfirmBtn = () => {
    if (tosSwitch) {
      setShowErrorMessage(false);
      if (userData?.subscription_information === null) {
        // if (isHealthSystemOrgnisation) {
        saveUsersSettings();
        // } else {
        //   saveInformation();
        //   !!onClickOfTosBtn && onClickOfTosBtn();
        // }
      } else {
        //call save information API
        saveUsersSettings();
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  const saveUsersSettings = async () => {
    let freeSubscription = {};
    if (
      userData?.is_existing_user &&
      !userData?.agreed_terms_of_service &&
      userData?.is_profile_completed
    ) {
      setIsExistingUser(true);
    } else {
      setIsExistingUser(false);
    }

    subscriptionObjEnum.forEach((subscription) => {
      //assigning enterprise subscrtion to all the user as per PORTAL-1076
      if (
        subscription.name === subscriptionEnum.FREE &&
        subscription.frequency === subscriptionFrequencyEnum.YEARLY
      ) {
        freeSubscription = subscription;
      }
    });
    const token = await getIdTokenClaims();
    var params = {
      agreed_terms_of_service: tosSwitch,
    };
    var subscription = '';
    if (isHealthSystemOrgnisation && userData?.subscription_information === null) {
      params.subscription_guid = freeSubscription.guid;
      subscription = freeSubscription.name;
    }
    dispatch(
      updateProfileInfo({
        params: params,
        userGuid: userData.guid,
        token: getRawToken(token),
      }),
    );
    setCallProfileCompleted(true);
    document.body.classList.remove('hide-chat');
    dispatch(setOnboardingData({}));
  };

  const getProfileData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestGetUserProfile({
        token: getRawToken(token),
      }),
    );
  };

  useEffect(() => {
    history.push(urlConstantsEnum.ONBOARDING_STEP_2);
  }, []);

  /* //assigning enterprise subscrtion to all the user as per PORTAL-1076
  useEffect(() => {
    if (isUpdatedProfileCompleted && isCallProfileCompleted) {
      redirectToHomeScreen();
    }
  }, [isUpdatedProfileCompleted]);

  const redirectToHomeScreen = async (subscription) => {
    const token = await getIdTokenClaims();
    dispatch(completeProfile({ userGuid: userData.guid, token: getRawToken(token) }));
    var subscriptionType = '';
    if (subscription) {
      subscriptionType = subscription;
    } else {
      subscriptionType = userData?.subscription_information.name;
    }
    if (!isExistingUser) {
      dispatch(
        setVideoModalDisplay({
          isDisplayModal: true,
          subscriptionType: subscriptionType,
        }),
      );
    }
    
    getProfileData();
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };*/

  const onClickOfTosBackButton = () => {
    saveInformation();
    !!onClickOfTosBackBtn && onClickOfTosBackBtn();
  };

  const saveInformation = () => {
    let onboardingDataObj = {
      agreed_terms_of_service: tosSwitch,
      subscription_guid: onboardingData?.subscription_guid,
      subscription_frequency: onboardingData?.subscription_frequency,
    };
    dispatch(setOnboardingData(onboardingDataObj));
  };

  return (
    <>
      {isLoading || isProfileUpdateStarted ? (
        <div className="position-relative">
          <AuthLoader fullScreen={false} />
        </div>
      ) : (
        <>
          {showErrorMessage && (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">
                Please accept our Terms of Service to continue
              </div>
            </div>
          )}
          <div className="onboarding-section-header my-4">Terms of Service</div>
          <div className="tos-bg p-3">
            <div className={`tos-container p-3 ${showErrorMessage ? 'tos-error' : ''}`}>
              <div className="mb-3">Last Updated: October 2, 2024</div>
              <div className="mb-3">
                These Atropos Terms of Service (“<strong>Terms</strong>”) set out terms
                and conditions for you or the entity you represent (“you”) to access and
                use the websites, mobile applications, products, services, and other
                resources, including Atropos’s evidence platform GENEVA OS and ChatRWD
                (collectively, the <strong>“Platform”</strong>) and the reports and other
                output it generates <strong>(“Deliverables” or “Reports”)</strong>{' '}
                (collectively, the <strong>“Services”</strong>) provided by Atropos
                Health, Inc., a Delaware corporation and its affiliates (
                <strong>“Atropos”</strong>, <strong>“we”</strong>, <strong>“us”</strong>,
                or <strong>“our”</strong> ). By using the Services, you agree to these
                Terms. These Terms govern your use of the Services and create a binding
                legal agreement that we may enforce against you in the event of a
                violation, unless you and Atropos have entered into a written Master
                Services Agreement (<strong>“MSA”</strong>), in which case such MSA, and
                not these Terms, will govern your access to and use of the Services
                covered by the MSA. If you do not agree to all of these Terms, do not use
                the Services.
              </div>

              <div className="mb-3">
                <strong>
                  SECTION 18 OF THESE TERMS CONTAINS A MANDATORY ARBITRATION PROVISION
                  THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS AND LIMITS
                  THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.
                </strong>
              </div>

              <ol>
                <li>
                  <strong>Eligibility.</strong> You must be at least eighteen (18) years
                  old to use the Services. By agreeing to these Terms, you represent and
                  warrant to us that: (a) you are at least eighteen (18) years old, and
                  (b) your registration and your use of the Services is in compliance with
                  any and all applicable laws and regulations. If you are an entity,
                  organization, or company, the individual accepting these Terms on your
                  behalf represents and warrants that they have authority to bind you to
                  these Terms and you agree to be bound by these Terms.
                </li>
                <li>
                  <strong>Account Registration.</strong> In order to use the Services you
                  must register for an account (or permit Atropos to register an account
                  on your behalf). Registration requires you to provide us with your name,
                  email address, and in some cases your profession, specialty, and other
                  information specified in the registration form (“Registration
                  Information”), and to select a username and password that will be
                  associated with your account. You agree that your Registration
                  Information is true, accurate, current, and complete, and you will
                  promptly update your Registration Information as necessary so that it
                  continues to be true, accurate, current and complete. We may attempt to
                  verify the accuracy of the Registration Information that you have
                  provided and update it as necessary. You are solely responsible for
                  maintaining the confidentiality and security of your Atropos account
                  username and password and you may not permit another person to use your
                  username and password to access the Services. You are responsible for
                  all activity that occurs under your account. If you believe that the
                  security of your account information has been compromised, you should
                  immediately change your username and password through the account
                  settings feature or notify us and we will assist you. We will have no
                  liability for any unauthorized access to or use of your account
                  information.
                </li>
                <li>
                  <strong>Role of Atropos and Disclaimer.</strong> You acknowledge that
                  Atropos is not engaged in rendering medical, pharmacologic or health
                  care advice or services and the professional duty to the patient in
                  providing healthcare services lies solely with the applicable healthcare
                  provider and/or medical facility (“<strong>Medical Facility</strong>”).
                  Deliverables are intended to be a supplement to, and not a substitute
                  for the knowledge, expertise, skill, and judgment of the Medical
                  Facility in patient care. They are in no way intended to serve as a
                  diagnostic service or platform, to provide certainty with respect to a
                  diagnosis, to recommend a particular product or therapy or to otherwise
                  substitute for the clinical judgment of a qualified healthcare
                  professional. Atropos does not assume any responsibility for the actions
                  of any Medical Facility which may result in liability or damages due to
                  malpractice, failure to warn, negligence, or any other basis.
                  <div className="mb-3"></div>
                  If you are a consumer who chooses to access the professional-level
                  information made available through the Services, you should not rely on
                  that information as professional medical advice or use the Services as a
                  replacement for any relationship with your physician or other qualified
                  healthcare professional. For medical concerns, including decisions about
                  medications and other treatments, consumers should always consult their
                  physician or, in serious cases, seek immediate assistance from emergency
                  medical personnel.
                  <div className="mb-3"></div>
                  You understand and agree that Atropos’s ability to provide the Services
                  is dependent on your timely provision of relevant data and other
                  materials and you agree to provide the same to Atropos in a timely
                  manner and in the format as required by Atropos. Atropos is not liable
                  for any delay or failure of performance to the extent caused by your
                  delay in performing or failure to perform any of your obligations under
                  these Terms, including your failure to provide the information necessary
                  to generate a Deliverable.
                </li>
                <li>
                  <strong>Access.</strong> Subject to your compliance with these Terms,
                  you are granted a limited, non-exclusive, non-transferable and
                  non-sublicensable right to access and use the Services and to view the
                  information and materials made available through the Services, including
                  User Content (as defined below) (collectively, “
                  <strong>Atropos Content</strong>”) solely for your personal and
                  professional use. You shall not use, copy, adapt, modify, prepare
                  derivative works based upon, distribute, license, sell, transfer,
                  publicly display, publicly perform, transmit, stream, broadcast or
                  otherwise exploit the Services or Atropos Content except as expressly
                  permitted in these Terms. All rights not expressly granted herein are
                  reserved by us and our respective licensors, as applicable.
                  <div className="mb-3"></div>
                  The Services may contain links to third-party websites or resources
                  which are not part of Atropos. You agree that we are not responsible or
                  liable for these websites and resources including, without limitation,
                  their availability or the content and information that they provide. The
                  inclusion in the Services of third party resources, including links to
                  third party websites, does not imply our endorsement of these resources.
                </li>
                <li>
                  <strong>User Responsibilities.</strong>
                  You solely are responsible for (i) any User Content provided to Atropos,
                  and (ii) the acts and omissions of any third party or authorized user
                  who accesses the Services on your behalf or through your account, either
                  with or without knowledge or consent. Except as required by law, you
                  will not, and will not permit or encourage anyone else, to:
                  <div className="mb-3"></div>
                  <ol type="a">
                    <li>
                      Access or use the Services for purposes other than as permitted in
                      the Agreements; use the Services for unlawful purposes or in a
                      manner that violates any law or regulation; or commit fraud or
                      falsify information in connection with any use of the Services;
                    </li>
                    <li>
                      Except as expressly permitted under the Agreements, sell, license,
                      rent, distribute, disseminate, or publish the Services, or any part
                      thereof, or prepare any derivative works of or otherwise modify the
                      Services;
                    </li>
                    <li>
                      Obscure, remove, or alter any copyright notice or other intellectual
                      property right markings, designations, or notices in or on the
                      Services;
                    </li>
                    <li>
                      Attempt to identify, including by inference, any individual who is
                      the subject of any Deliverable or the source of any data included in
                      the Platform;
                    </li>
                    <li>
                      Use the Services to develop, create, produce, enhance, or add to any
                      database; develop, assist in developing, or have developed on your
                      behalf or any other person software, technology, or services that
                      compete with or are substantially similar to the Services; access,
                      use, or analyze the Services for any purpose that is to Atropos’s
                      detriment or commercial disadvantage; or use the Services in a
                      manner that infringes, violates, or misappropriates Atropos’s or any
                      third party’s rights, including intellectual property rights and
                      privacy rights;{' '}
                    </li>
                    <li>Use the Services for publicity purposes;</li>
                    <li>
                      Resell or redistribute the Services or any Deliverable (or any
                      portion thereof or derivative work thereof) to any other party on a
                      standalone basis;{' '}
                    </li>
                    <li>
                      Use the Services for any for any invasive, infringing, defamatory or
                      fraudulent purpose (for example, this may include phishing, creating
                      a pyramid scheme or mirroring a website);
                    </li>
                    <li>
                      Distribute viruses, worms, Trojan horses, corrupted files, hoaxes,
                      or other items of a destructive or deceptive nature;
                    </li>
                    <li>
                      Interfere with the use of the Services, or the equipment used to
                      provide the Services;
                    </li>
                    <li>
                      Disable, interfere with or circumvent any aspect of the Services;
                    </li>
                    <li>
                      Generate, distribute, publish or facilitate unsolicited mass email,
                      promotions, advertisings or other solicitations (“spam”);{' '}
                    </li>
                    <li>
                      License, sublicense, sell, resell, transfer, assign, distribute, use
                      as a service bureau or timeshare, or otherwise commercially exploit
                      or make the Services available to any third party in any way;
                    </li>
                    <li>
                      Modify, disassemble or make derivative works based upon the Services
                      or otherwise attempt to derive source code or other trade secrets
                      from the Services; reverse engineer or decompile the Services to
                      reach the source code of your Services; or
                    </li>
                    <li>
                      Access the Services if you are a competitor of Atropos or do not
                      meet the criteria specified by Atropos in order to access or use a
                      particular Service.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Use of Deliverables.</strong> You may only use Deliverables as
                  permitted in and in accordance with these Terms, and all applicable law.
                  You agree to carefully review and analyze all Deliverables. You are
                  responsible for the adoption and implementation of any and all
                  Deliverables and for all results and consequences of decisions made by
                  or on your behalf. Atropos is not responsible for your use of or
                  reliance on any Deliverables. You may not share, distribute, or disclose
                  any Deliverable, or otherwise use any Deliverable for the benefit of any
                  third party, except as explicitly permitted in these Terms.
                </li>
                <li>
                  <strong>Consent to Use of Artificial Intelligence.</strong> The Services
                  may include the option to engage large language model-based, generative
                  AI applications (“<strong>AI</strong>”) as a part of the Services and,
                  in particular, the generation of Deliverables (the “
                  <strong>AI-Enabled Services</strong>”). If you choose to opt-in to the
                  AI-Enabled Services, you understand and agree that the Deliverables may
                  be generated, in part, by AI rather than a human being. Use of the
                  AI-Enabled Services may offer certain potential benefits (e.g., faster
                  turnaround times), but also present certain potential risks (e.g., delay
                  caused by malfunctioning third party AI tool). By agreeing to these
                  Terms, you represent and warrant that you have had the opportunity to
                  consider the risks and benefits of the use of the AI-Enabled Services
                  and that you have provided voluntary informed consent to the provision
                  of the AI-Enabled Services to you.
                </li>
                <li>
                  <strong>User Content.</strong>
                  <ol type="a">
                    <li>
                      <strong>Generally.</strong> Certain features of the Services permit
                      users to submit content, including, without limitation, text,
                      images, figures, charts, graphics, reports, or data (“
                      <strong>User Content</strong>”). User Content does not include
                      Registration Information.
                    </li>
                    <li>
                      <strong>Limited License Grant to Atropos.</strong> By providing User
                      Content to or via the Services, you grant Atropos a worldwide,
                      non-exclusive, royalty-free, perpetual, fully paid right and license
                      (with the right to sublicense) to host, store, transfer, display,
                      perform, reproduce, prepare derivative works based upon, modify for
                      the purpose of formatting for display, train artificial intelligence
                      on, and distribute your User Content, in whole or in part, in any
                      media formats and through any media channels now known or hereafter
                      developed.
                    </li>
                    <li>
                      <strong>User Content Representations and Warranties.</strong>{' '}
                      Atropos disclaims any and all liability in connection with User
                      Content. You are solely responsible for ensuring that the User
                      Content that you make available through the Services complies with
                      applicable laws including, but not limited to, those related to
                      privacy. Prior to submitting any User Content to the Services, you
                      must remove any information that identifies an individual or could
                      reasonably enable the identification of an individual, e.g., name,
                      e-mail address, social security number, insurance number or other
                      unique identification number, biometric identifiers, facial
                      photographs, photographs of identifying marks such as tattoos or
                      scars. You shall be solely responsible for any claims arising from
                      your failure to de-identify User Content that you submit through the
                      Services.
                    </li>
                    <li>
                      By providing User Content via the Services, you affirm, represent,
                      and warrant that:
                      <ol type="i">
                        <li>
                          You are the creator and owner of the User Content, or have the
                          necessary licenses, rights, consents, and permissions to
                          authorize Atropos to use and distribute your User Content as
                          necessary to exercise the licenses granted by you in this
                          Section, in the manner contemplated by Atropos, the Services,
                          and these Terms;
                        </li>
                        <li>
                          Your User Content, and the use of your User Content as
                          contemplated by these Terms, does not and will not:{' '}
                          <ol>
                            <li>
                              infringe, violate, or misappropriate any third party right,
                              including any copyright, trademark, patent, trade secret,
                              moral right, privacy right, right of publicity, or any other
                              intellectual property or proprietary right;
                            </li>
                            <li>
                              results in the violation of any applicable law or
                              regulation, including, but not limited to, the Health
                              Insurance Portability and Accountability Act (HIPAA) or any
                              other applicable privacy laws; or
                            </li>
                            <li>
                              slander, defame, libel, or invade the right of privacy,
                              publicity or other property rights of any other person; or
                              (iii) cause Atropos to violate any law or regulation.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Protected Health Information.</strong> You agree not to
                      provide, transmit, otherwise disclose to, nor make accessible to the
                      Services or to Atropos, any personal information or other
                      information that identifies or could be used, either alone or in
                      combination with other information, to identify any patient or other
                      natural person that is subject to federal or state data protection
                      laws, including without limitation, any Protected Health Information
                      (as defined by the Health Insurance Portability and Accountability
                      Act of 1996 and the rules and regulations promulgated thereunder).
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Ownership; Proprietary Rights.</strong> The Services are owned
                  and operated by Atropos. The visual interfaces, graphics, design,
                  compilation, information, data, computer code (including source code or
                  object code), products, software, services, and all other elements of
                  the Services (“<strong>Materials</strong>”) provided by Atropos are
                  protected by intellectual property and other laws. All Materials
                  included in the Service are the property of Atropos or its third party
                  licensors. Except as expressly authorized by Atropos, you may not make
                  use of the Materials. Atropos reserves all rights to the Materials not
                  granted expressly in these Terms.
                </li>
                <li>
                  <strong>Privacy Policy.</strong> The{' '}
                  <a
                    href="https://www.atroposhealth.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Atropos Privacy Policy
                  </a>{' '}
                  provides information about our collection, use and disclosure of
                  information about users of the Services. By accessing and using the
                  Services, you agree to the terms of the Privacy Policy and acknowledge
                  and agree that the Privacy Policy forms an integral part of these Terms.
                </li>
                <li>
                  <strong>Feedback.</strong> You may provide suggestions, comments, or
                  other feedback (collectively, “<strong>Feedback</strong>”) to Atropos
                  with respect to the Services. You grant Atropos an as-is, non-exclusive,
                  world-wide, perpetual, royalty-free, fully paid license to use the
                  Feedback for any purpose, including the development and exploitation of
                  its current and future products and services, without obligation of any
                  kind to you. Feedback is voluntary and is provided entirely “as-is”,
                  without warranties of any kind. All use of the Feedback is at Atropos’s
                  sole risk and liability.
                </li>
                <li>
                  <strong>Beta and Free Services.</strong> Atropos may make new features
                  of the Services available to you or make portions of the Services
                  available at no charge. Notwithstanding any other provision herein,
                  Services provided at no charge (“<strong>Free Services</strong>”) or
                  released as beta, pilot, limited release, non-production or evaluation
                  (“<strong>Beta Features</strong>”) are made available to you “as-is”
                  without any warranty, support, maintenance or other obligation of any
                  kind. Atropos may terminate access to, or use of, a Free Feature or Beta
                  Feature at any time.
                </li>
                <li>
                  <strong>No Medical Advice.</strong> Atropos is not a healthcare
                  provider, and Atropos Content is not intended to provide medical advice,
                  diagnosis or treatment or substitute for an individual patient
                  assessment based on a qualified health care provider’s evaluation of
                  each patient, including factors unique to such patient. Atropos is not
                  responsible or liable for any advice, course of treatment, diagnosis, or
                  any other information or services, including health care services, that
                  you may obtain through your healthcare provider.{' '}
                  <strong>
                    IF YOU HAVE ANY QUESTIONS ABOUT YOUR HEALTH, INCLUDING ANY MEDICAL
                    CONDITION OR TREATMENT, PLEASE CONTACT YOUR HEALTHCARE PROVIDER OR, IF
                    YOU HAVE A MEDICAL EMERGENCY, SEEK IMMEDIATE MEDICAL HELP OR CALL
                    EMERGENCY SERVICES AT 911 (OR YOUR LOCAL MEDICAL EMERGENCY NUMBER).
                  </strong>
                </li>
                <li>
                  <strong>Changes to Terms.</strong> Atropos may modify these Terms at any
                  time and in our sole discretion. If a change to these Terms materially
                  modifies your rights or obligations, you will be required to accept the
                  modified Terms in order to continue to use the Services. Material
                  modifications are effective upon your acceptance of the modified Terms.
                  Immaterial modifications are effective upon publication. Disputes
                  arising under these Terms will be resolved in accordance with the
                  version of these Terms that was in effect at the time the dispute arose.
                </li>
                <li>
                  <strong>Term, Termination. </strong>
                  <ol type="a">
                    <li>
                      <strong>Term</strong> These Terms are effective beginning when you
                      accept the Terms or first access or use the Services and ending as
                      described in this Section.
                    </li>
                    <li>
                      <strong>Termination.</strong> Atropos may, at its sole discretion,
                      terminate these Terms or your account on the Services, or suspend or
                      terminate your access to the Services, at any time for any reason or
                      no reason, with or without notice. You may terminate your account
                      and these Terms at any time by clicking the unsubscribe link in your
                      account or contacting customer service at support@atroposhealth.com.
                    </li>
                    <li>
                      <strong>Effect of Termination.</strong> Upon termination of these
                      Terms: (a) your use rights will terminate and you must immediately
                      cease all use of the Services; (b) you will no longer be authorized
                      to access your account or the Services; (c) you must pay Atropos any
                      unpaid amount that was due prior to termination; and (d) all payment
                      obligations accrued prior to termination and{' '}
                      <strong>Sections 3, 5, 6, 8, 9, 16, 17, and 18</strong> will
                      survive.
                    </li>
                    <li>
                      <strong>Modification of the Service.</strong> Atropos reserves the
                      right to modify or discontinue the Services at any time (including
                      by limiting or discontinuing certain features of the Services),
                      temporarily or permanently, without notice to you. Atropos will have
                      no liability for any change to the Services or any suspension or
                      termination of your access to or use of the Services. If Atropos
                      discontinues the Services, Atropos will provide you a refund of any
                      unused portions of prepaid fees.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Indemnification.</strong> You agree to indemnify, defend, and
                  hold harmless Atropos and its Related Persons (as defined below) from
                  and against any and all third-party suits, actions, claims, proceedings,
                  damages, settlements, judgments, injuries, liabilities, obligations,
                  losses, risks, costs, and expenses (including, without limitation,
                  reasonable attorneys’ fees, litigation expenses, and accounting fees),
                  relating to or arising from, or alleged to arise from, your use of the
                  Services in an unauthorized manner, fraud, violation of law, or willful
                  misconduct, or any breach by you of these Terms.
                </li>
                <li>
                  <strong>Disclaimer of Warranties; Limitation of Liability.</strong>
                  YOU EXPRESSLY AGREE THAT USE OF THE SERVICES AND CONTENT IS AT YOUR SOLE
                  RISK. YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED ON AN “AS
                  IS” AND “AS AVAILABLE” BASIS. ATROPOS AND ITS AFFILIATES, AND THEIR
                  RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, PARTNERS, MEMBERS, EMPLOYEES,
                  AND AGENTS (COLLECTIVELY “<strong>RELATED PERSONS</strong>”) MAKE NO
                  REPRESENTATIONS OR WARRANTIES AND EXPRESSLY DISCLAIM ANY AND ALL
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THE
                  SERVICES INCLUDING, BUT NOT LIMITED TO, ANY REPRESENTATIONS OR
                  WARRANTIES WITH RESPECT TO MERCHANTABILITY, FITNESS FOR A PARTICULAR USE
                  OR PURPOSE, NONINFRINGEMENT, TITLE, AVAILABILITY, SECURITY, OPERABILITY,
                  CONDITION, QUIET ENJOYMENT, VALUE, ACCURACY OF DATA, FREEDOM FROM
                  VIRUSES OR MALWARE, COMPLETENESS, TIMELINESS, FUNCTIONALITY,
                  RELIABILITY, SEQUENCING OR SPEED OF DELIVERY OR SYSTEM INTEGRATION. WE
                  MAKE NO WARRANTIES OR REPRESENTATIONS THAT YOUR USE OF THE SERVICES WILL
                  NOT INFRINGE THE RIGHTS OF THIRD PARTIES.
                  <div className="mb-3"></div>
                  TO THE FULLEST EXTENT OF APPLICABLE LAW, NEITHER ATROPOS NOR ITS RELATED
                  PERSONS WILL BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON
                  CONTENT OBTAINED THROUGH THE SERVICES. IT IS YOUR RESPONSIBILITY TO
                  EVALUATE THE ACCURACY, COMPLETENESS, TIMELINESS, RELIABILITY OR
                  USEFULNESS OF THE SERVICES. FURTHERMORE, ATROPOS DOES NOT GUARANTEE THAT
                  THE SERVICES WILL BE UNINTERRUPTED, OR FREE FROM ERROR, DEFECT, LOSS,
                  DELAY IN OPERATION, CORRUPTION, CYBER ATTACK, VIRUSES, INTERFERENCE,
                  HACKING, MALWARE, OR OTHER SECURITY INTRUSION, AND ATROPOS DISCLAIMS ANY
                  LIABILITY RELATING THERETO.
                  <div className="mb-3"></div>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE
                  MAXIMUM AGGREGATE LIABILITY OF ATROPOS OR THE RELATED PERSONS (JOINTLY)
                  ARISING OUT OF OR IN ANY WAY RELATED TO ACCESS OR USE OF THE SERVICES,
                  EXCEED THE GREATER OF $50 OR THE AMOUNT YOU PAID TO US TO USE THE
                  SERVICES IN THE ONE (1) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON
                  WHICH YOUR CLAIM AROSE. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IN
                  THE EVENT YOUR REMEDIES HEREUNDER FAIL OF THEIR ESSENTIAL PURPOSE, AND
                  THE FOREGOING SHALL CONSTITUTE ATROPOS AND ITS RELATED PERSONS’ SOLE
                  LIABILITY AND OBLIGATION IN RESPECT HEREOF, REGARDLESS OF THE FORM OF
                  ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO,
                  SIMPLE NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR ANY OTHER
                  LEGAL OR EQUITABLE THEORY
                </li>
                <li>
                  <strong>Dispute Resolution.</strong> PLEASE READ THIS SECTION CAREFULLY
                  BECAUSE IT REQUIRES YOU AND ATROPOS TO RESOLVE ALL DISPUTES BETWEEN US
                  THROUGH BINDING INDIVIDUAL ARBITRATION AND LIMITS THE MANNER IN WHICH
                  YOU CAN SEEK RELIEF FROM ATROPOS.
                  <div className="mb-3"></div>
                  Except for any disputes, claims, suits, actions, causes of action,
                  demands or proceedings (collectively, “<strong>Disputes</strong>”) in
                  which either party seeks to bring an individual action in small claims
                  court or seeks injunctive or other equitable relief for the alleged
                  unlawful use of intellectual property, including, without limitation,
                  copyrights, trademarks, trade names, logos, trade secrets or patents,
                  YOU AND ATROPOS AGREE (A) TO WAIVE YOUR AND ATROPOS’S RESPECTIVE RIGHTS
                  TO HAVE ANY AND ALL DISPUTES ARISING FROM OR RELATED TO THESE TERMS, OR
                  THE SERVICES, RESOLVED IN A COURT, AND (B) TO WAIVE YOUR AND ATROPOS’S
                  RESPECTIVE RIGHTS TO A JURY TRIAL. Instead, you and Atropos agree to
                  arbitrate Disputes through binding arbitration (which is the referral of
                  a dispute to one or more persons charged with reviewing the dispute and
                  making a final and binding determination to resolve it instead of having
                  the dispute decided by a judge or jury in court) conducted by the
                  American Arbitration Association (“<strong>AAA</strong>”) under the
                  arbitration rules in effect at the time the arbitration is initiated
                  (the “AAA Rules”) and under the rules set forth in these Terms. If there
                  is a conflict between the <strong>AAA Rules</strong> and the rules set
                  forth in these Terms, the rules set forth in these Terms will govern.
                  <div className="mb-3"></div>
                  Unless the parties agree otherwise, the arbitration will be conducted in
                  New York, New York. Each party will be responsible for paying any
                  filing, administrative and arbitrator fees in accordance with the AAA
                  Rules. The award rendered by the arbitrator shall include costs of
                  arbitration, reasonable attorneys’ fees and reasonable costs for expert
                  and other witnesses. Any judgment on the award rendered by the
                  arbitrator may be entered in any court of competent jurisdiction.
                  ARBITRATION MEANS THAT YOU WAIVE YOUR RIGHT TO A JURY TRIAL. YOU AGREE
                  THAT, BY ENTERING INTO THESE TERMS, YOU ARE WAIVING THE RIGHT TO A TRIAL
                  <div className="mb-3"></div>
                  You and Atropos agree that these Terms affect interstate commerce and
                  that the enforceability of this Section shall be both substantively and
                  procedurally governed by and construed and enforced in accordance with
                  the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “
                  <strong>FAA</strong>”), to the maximum extent permitted by applicable
                  law.
                  <div className="mb-3"></div>
                  YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY
                  DISPUTE ARISING OUT OF OR RELATED TO THE SERVICES MUST BE FILED WITHIN
                  ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE, OR SUCH DISPUTE
                  WILL BE FOREVER BARRED.
                  <div className="mb-3"></div>
                  These Terms, and your access to and use of the Services shall be
                  governed by and construed and enforced in accordance with the laws of
                  the State of Delaware, without regard to conflict of law rules or
                  principles that would cause the application of the laws of any other
                  jurisdiction. Any Dispute between the parties that is not subject to
                  arbitration or cannot be heard in small claims court, shall be resolved
                  on an individual basis in the state or federal courts of the State of
                  Delaware and the United States, respectively, sitting in Newcastle
                  County, Delaware.
                  <div className="mb-3"></div>
                  All parts of these Terms apply to the maximum extent permitted by law.
                  Atropos and you both agree that if we cannot enforce a part of this
                  contract as written, then that part will be replaced with terms that
                  most closely match the intent of the part we cannot enforce, to the
                  extent permitted by law. The invalidity of part of these Terms of Use
                  will not affect the validity and enforceability of the remaining
                  provisions. The section headings are for convenience only and do not
                  have any force or effect.
                </li>
                <li>
                  <strong>No Waiver.</strong> No waiver by Atropos of any term or
                  condition set forth in these Terms shall be deemed a further or
                  continuing waiver of such term or condition or a waiver of any other
                  term or condition, and any failure by Atropos to assert a right or
                  provision under these Terms shall not constitute a waiver of such right
                  or provision.
                </li>
                <li>
                  <strong>Complete Agreement.</strong> Except as expressly provided in a
                  particular “legal notice” on the website, these Terms (including the
                  Atropos Privacy Policy) constitute the entire agreement between you and
                  Atropos with respect to your use (and prior use) of the Services.
                  <div className="mb-3"></div>
                  These Terms constitute the entire and exclusive understanding and
                  agreement between Atropos and you regarding the Services and these Terms
                  supersede and replace any and all prior oral or written understandings
                  or agreements between Atropos and you regarding Atropos and the Services
                  provided.
                </li>
                <li>
                  <strong>Assignment.</strong> You may not assign or transfer these Terms,
                  by operation of law or otherwise, without our prior written consent. Any
                  attempt by you to assign or transfer these Terms, without such consent,
                  will be null and of no effect. We may assign or transfer these Terms, at
                  our sole discretion, without restriction. Subject to the foregoing,
                  these Terms will bind and inure to the benefit of the parties, their
                  successors and permitted assigns. Further, any of us may exercise the
                  rights described in these Terms.
                </li>
                <li>
                  <strong>Notices.</strong> Any notices or other communications permitted
                  or required hereunder, including those regarding material modifications
                  to these Terms, will be in a written form and given: (i) by us via email
                  (in each case to the email address included in your Registration
                  Information); or (ii) by posting within the Platform. For notices made
                  by e-mail, the date of receipt will be deemed the date on which such
                  notice is transmitted.
                </li>
                <li>
                  <strong>Contact Us.</strong> If you have questions about the Services or
                  these Terms, or you wish to provide feedback, please contact us at
                  support@atroposhealth.com.
                </li>
                <li>
                  <strong>Atropos Trademarks.</strong> Except as may be required by
                  applicable law or regulation, you will not, without Atropos’s prior
                  written consent, use the names, logos, tradenames, trademarks, or
                  service marks of Atropos or its Affiliates.
                </li>
              </ol>
            </div>
            <div className="d-md-flex flex-justify-between mt-3">
              <div className="flex-self-center mb-md-0 mb-3">
                Agree to our Terms of Service?
              </div>
              <div className="d-flex flex-self-center tos-switch">
                <div>Decline</div>

                <Switch
                  checkedIcon={false}
                  onColor="#000000"
                  offColor="#F7F7F7"
                  height={20}
                  width={30}
                  uncheckedIcon={false}
                  onChange={handleSwitchChange}
                  checked={tosSwitch}
                  className="flex-self-center mx-3"
                  id={'tos-switch'}
                />
                <div>Accept</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-justify-end mt-3">
            <ButtonCustom
              cssClass={`back-btn py-3 px-3 mr-3 width-50`}
              onClick={onClickOfTosBackButton}
              buttonAction={'Onboarding TOS back button clicked'}
              id={'btn-onboarding-tos-back-button'}
            >
              Back
            </ButtonCustom>
            <ButtonCustom
              cssClass={`gradient-btn py-3 px-3 width-50`}
              onClick={onClickOfTosConfirmBtn}
              id={'btn-onboarding-tos-confirm-button'}
              buttonAction={'Onboarding TOS confirm clicked'}
              actionLabel={'Onboarding information submitted'}
            >
              {isLoading ? 'Loading...' : 'Confirm and Continue'}
            </ButtonCustom>
          </div>
          {!!updateProfileError && userData?.subscription_information !== null ? (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">{updateProfileError}</div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default TermsOfServices;
