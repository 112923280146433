import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ReactComponent as Refresh } from 'assets/images/Refresh.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { Button } from 'components/button';

function StudyDetailsSummary({ studyFormatObject, index, onClick, children, ...props }) {
  const {
    handleButtonAction,
    ordersPageData: { chatRWD },
    renderIconTitle,
  } = useOrdersPagedata();

  const { studyFormats, isOpenAiLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { isPICOTFinalized } = chatRWD;

  const isNotOnCurrentFrame =
    studyFormats?.length - 1 > props?.optionIndex ||
    isOpenAiLoading ||
    isPICOTFinalized ||
    false;

  function renderIcon(icon) {
    switch (icon) {
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        return (
          <Refresh className="cursor-pointer" onClick={() => handleIconClick(icon)} />
        );
      case aiButtonsEnum.MANUALLY_EDIT:
        return (
          <EditIcon
            className="cursor-pointer"
            onClick={() => {
              if (!isNotOnCurrentFrame) props?.onEdit(icon);
            }}
          />
        );
      case aiButtonsEnum.REGENERATE_PICOT:
        return (
          <Refresh
            className="cursor-pointer"
            onClick={() => {
              if (!isNotOnCurrentFrame) props?.onRefresh(icon);
            }}
          />
        );
      default:
    }
  }

  function handleIconClick(key) {
    if (!isNotOnCurrentFrame)
      handleButtonAction({
        key,
        index: props?.optionIndex,
      });
  }

  return (
    <div className="w-80">
      {studyFormatObject?.reason !== '' && studyFormatObject?.reason !== undefined ? (
        <div className="mb-3 txt size-16 gray-20 lh-28 intro-text">
          {studyFormatObject?.reason}
        </div>
      ) : (
        <>
          {studyFormatObject?.display_text_1 && (
            <div
              className="mb-3 txt size-16 gray-20 lh-28 intro-text"
              dangerouslySetInnerHTML={{
                __html: studyFormatObject?.display_text_1,
              }}
            />
          )}
        </>
      )}

      {children}

      {studyFormatObject?.inline_buttons &&
      studyFormatObject?.inline_buttons?.length > 0 ? (
        <div className="d-flex mt-3 w-100 gap-20">
          {studyFormatObject?.display_text_2 && (
            <div className="txt size-16 lh-28 conclusion-text text-gray-700">
              {studyFormatObject.display_text_2}
            </div>
          )}
          <div className="d-flex gap-5 justify-content-end ml-auto pt-2">
            {!isNotOnCurrentFrame &&
              studyFormatObject?.inline_buttons?.map((icon) => {
                console.log(icon === aiButtonsEnum.REGENERATE_PICOT);
                if (icon === aiButtonsEnum.REGENERATE_PICOT && props.optionIndex !== 0) {
                  return null;
                }

                return (
                  <Button
                    icon={renderIcon(icon)}
                    title={renderIconTitle({ icon })}
                    key={icon}
                    text
                    buttonaction={`${renderIconTitle({ icon })} button clicked`}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        studyFormatObject?.display_text_2 && (
          <div className="mt-3 txt size-16 lh-28 conclusion-text text-gray-700">
            {studyFormatObject.display_text_2}
          </div>
        )
      )}
    </div>
  );
}

export default StudyDetailsSummary;
