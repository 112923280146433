const Strings = {
  pendingDesc: 'You can still edit this request by clicking Edit Order below.',
  inProgressDesc: 'If you need to make changes to this order, ',
  completedDesc: 'Schedule a call to review your results below.',
  documentSizeError: 'Make sure your file size is less than or equal to 25MB.',
  documentExtensionError:
    'Make sure your file is using any of these format (docx, PDF, CSV, xlsx, or pptx).',
  documentExtensionNote:
    'Make sure to use any of these format docx, pdf, csv, xlsx, pptx.',
  phiModalText1:
    'Please confirm the uploaded document does not contain identifiable medical records.',
  phiModalText2:
    'Confirm the uploaded document is not an entire medical record or files with unstructured data.',
  freeSubscriptionVideoHeaderText: 'You’re on a FREE account that gives you:',
  standardSubscriptionVideoHeaderText:
    "Congratulations! You've subscribed to the Atropos Evidence Portal - Standard Plan.",
  standardSubscriptionVideoSubText:
    'You can now request Prognostograms, and view our growing library of evidence, directly from the Portal.',
  enterpriseSubscriptionVideoHeaderText:
    'Welcome to the future of evidence-based medicine!',
  enterpriseSubscriptionVideoSubText:
    'For the time being, you account is on the FREE plan.',
  enterpriseSubscriptionVideoSubText1:
    'If your organization has an enterprise subscription, our team will confirm and ensure your account is added to that subscription.',
  enterpriseSubscriptionVideoSubText2:
    'Otherwise somebody on our team will follow-up to further discuss our offerings.',
  onboardingExistingUserText:
    'Welcome back! We’re collecting a little bit more information about our users in order to build the best experience possible.',
  onboardingExistingUserTextForTOS:
    'Welcome back! We’ve made a few changes to our User Terms.',
  orderFirstNameError: 'First Name is Required',
  orderLastNameError: 'Last Name is Required',
  orderEmailError: 'Email is Required',
  orderOrgnisationError: 'Institution is Required',
  orderQuestionTypeError: 'Question Type is Required',
  patientLastNameError: 'Patient Last Name is Required',
  patientBirthDateError: 'Patient Birth Date is required',
  motivationError: 'Motivation is Required',
  phenotypeError: 'Unable to return an appropriate phenotype',
  phenotypeTableResponseText1:
    'Unfortunately, we cannot find matches for one or more phenotypes in your submitted PICO. If this study is still relevant without the unmatched concept(s), please continue by clicking “Phenotypes accurate”. If not, please click the “Manually edit PICO” button to try an alternative question or resubmit this question as a traditional Prognostogram request by selecting “Free Text” or “PICOT” at the top of the page.',
  phenotypeTableResponseText2:
    'Unfortunately, we cannot find matches for one or more phenotypes in your submitted PICO. Please click the “Manually edit PICO” button to try an alternative question or resubmit this question as a traditional Prognostogram request by selecting “Free Text” or “PICOT” at the top of the page.',
  phenotypeTableResponseText3:
    'Is this acceptable or would you like to modify any of the elements?',
  genderError: 'Please Select Gender',
  raceError: 'Please Select Race',
  ethnicityError: 'Please Select Ethnicity',
  dataSourceError: 'Data source is required',
  dataSourceSelectionError: 'Please select a data set',
  info: 'info',
  error: 'error',
  suggestion_title: 'Please see the following list of suggestions:',
  apply_any_suggestions: 'Would you like to use any of these suggestions?',
  suggestionGeneration: 'Generating Suggestions...',
  picot_with_suggestion_message: 'Please see the following Study',
  readyToSubmit: 'Are you ready to submit this study?',
  incorporatingSuggestionMessage: 'Incorporating suggestion...',
  generatingPicotMessage: 'Generating study...',
  savingsPicotMessage: 'Saving PICOT...',
  allFieldsMandatoryIndicator: 'All fields are mandatory.',
  startOver: 'Start Over?',
  confirmationMessage:
    'Are you sure you want to start over? You will lose all progress in this chat.',
  continueChattingModalButton: 'Continue Chatting',
  startOverModalButton: 'Start Over',
  cancelOrderRequestModalTitle: 'Cancel Request?',
  cancelOrderRequestModalDescription:
    'Are you sure you want to cancel this request and return to the Dashboard?',
  CancelRequestButton: 'Cancel Request',
  success: 'Success',
  unsuccessful: 'Unsuccessful',
  accessErrorMessage:
    'Oops! It looks like you do not have access to that Prognostogram. If you think this is an error, please reach out to our team at',
  supportEmail: 'Support@atroposhealth.com',
  downsampleError: 'Please enter a value between 100 and 10,000,000',
  outcomePeriodError: 'Follow up period End should be positive',
  order_cancellation_message: 'This Prognostogram will be cancelled.',
  confirmation_message: 'Are you sure?',
  cancel_order_action: 'Cancel order',
  Keep_it_action: 'Keep it',
  phenotypeGeneration: 'Generating phenotype definitions...',
  PrePostIndexError: 'Pre-Index and Post-Index values are required.',
  pleaseWait: 'Please wait...',
  suggestImprovements: 'Suggest Improvements',
  manuallyEdit: 'Manually Edit',
  regenerateStudy: 'Regenerate Study',
  startOver: 'Start Over',
  studyAccurate: 'Study Accurate',
  suggestAIImprovements: 'Suggest AI Improvements',
};

export { Strings };
