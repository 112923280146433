import React, { useState } from 'react';
import Table from 'components/Table';
import styles from './phenotype.module.scss';
import { Strings } from 'constants/Strings';
import { Button } from '@primer/components';
import { AiFillDelete } from 'react-icons/ai';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

const PAGE_LENGTH = 5;
function Codeset({ codeset, colspan, children, onDelete, phenotype }) {
  const [visibleItems, setVisibleItems] = useState(PAGE_LENGTH);
  const {
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();
  const isWorkflowDisabled = chatRWD?.isPhenotypeFinalized;

  const handleShowMore = () => {
    setVisibleItems((prevItems) => prevItems + PAGE_LENGTH);
  };

  const handleSeeLess = () => {
    setVisibleItems((prevItems) => prevItems - PAGE_LENGTH);
  };

  function handleDelete(codeSet) {
    if (!isWorkflowDisabled) {
      onDelete(codeSet);
    }
  }

  return (
    <>
      <Table.Row>
        <Table.TableData colSpan={colspan} className={styles.noneBorderBottom}>
          <Table className={styles.test}>
            <Table.TableHead>
              <Table.Row>
                <Table.TableHeader>Vocabulary</Table.TableHeader>
                <Table.TableHeader>Code</Table.TableHeader>
                <Table.TableHeader>Description</Table.TableHeader>
                <Table.TableHeader></Table.TableHeader>
              </Table.Row>
            </Table.TableHead>
            <Table.TableBody>
              {codeset?.slice(0, visibleItems).map((code, index) => (
                <React.Fragment
                  key={`${phenotype}-codeset-${code.concept_code}-${index}`}
                >
                  <Table.Row>
                    <Table.TableData className="bg-white">
                      {code.vocabulary}
                    </Table.TableData>
                    <Table.TableData className="bg-white">
                      {code.concept_code}
                    </Table.TableData>

                    <Table.TableData className="bg-white">
                      <span
                        className={
                          Strings.phenotypeError === code.concept_description
                            ? 'color-error'
                            : ''
                        }
                      >
                        {code.concept_description || code.concept_name}
                      </span>
                    </Table.TableData>
                    <Table.TableData className="bg-white text-center" disabled>
                      {codeset.length > 1 && (
                        <AiFillDelete
                          className={`mr-2 cursor-pointer ${
                            isWorkflowDisabled ? 'disabled' : ''
                          }`}
                          onClick={() => handleDelete({ index, code })}
                        />
                      )}
                    </Table.TableData>
                  </Table.Row>
                </React.Fragment>
              ))}

              {codeset.length > PAGE_LENGTH && (
                <Table.Row>
                  <Table.TableData className={styles.paginationRow}></Table.TableData>
                  <Table.TableData className={styles.paginationRow}></Table.TableData>
                  <Table.TableData className={styles.paginationRow}></Table.TableData>

                  <Table.TableData className={styles.paginationRow}>
                    <div className="d-flex gap-7 justify-content-end mt-2">
                      {/* {visibleItems < codeset.length ? (
                        <Button className="bg-white txt size-13" onClick={handleShowMore}>
                          Show More
                        </Button>
                      ) : (
                        <Button className="bg-white txt size-13" onClick={handleSeeLess}>
                          See Less
                        </Button>
                      )} */}

                      {(PAGE_LENGTH < visibleItems || visibleItems > codeset.length) && (
                        <Button className="bg-white txt size-13" onClick={handleSeeLess}>
                          See Less
                        </Button>
                      )}

                      {visibleItems < codeset.length && (
                        <Button className="bg-white txt size-13" onClick={handleShowMore}>
                          Show More
                        </Button>
                      )}
                    </div>
                  </Table.TableData>
                </Table.Row>
              )}
            </Table.TableBody>
          </Table>
        </Table.TableData>
      </Table.Row>
      {children}
    </>
  );
}

export default Codeset;
